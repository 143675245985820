import { useOnUnmount } from '@clubhouse/shared/hooks';
import { useCallback, useRef } from 'react';
import { isPlaywrightTestEnv } from 'utils/isPlaywrightTestEnv';
import { EVENTS, logEvent } from 'utils/monitoring';
import { createAlert } from 'utils/offline';
const FailedToConnectToast = createAlert({
  kind: 'alert',
  message: 'The server connection for receiving real-time updates could not be established. Please try reloading the page, and contact support if the error persists.'
});
export const useDelayedWarning = () => {
  const timeoutId = useRef(null);
  const show = useCallback(() => {
    if (isPlaywrightTestEnv()) return;
    if (timeoutId.current) return;
    timeoutId.current = setTimeout(() => {
      if (!FailedToConnectToast.isActive()) logEvent(EVENTS.SseConnection_Failed);
      FailedToConnectToast.show();
    }, 5000);
  }, []);
  const hide = useCallback(() => {
    if (timeoutId.current) clearTimeout(timeoutId.current);
    timeoutId.current = null;
    FailedToConnectToast.hide();
  }, []);
  useOnUnmount(() => {
    if (timeoutId.current) clearTimeout(timeoutId.current);
    timeoutId.current = null;
  });
  return {
    show,
    hide
  };
};
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { isLoggedInPage, isRecoveryCodePage } from 'utils/is';
import { logoutAndRedirect } from 'utils/logout';
import { logError } from 'utils/monitoring';
const shouldRedirectOn401 = () => isLoggedInPage() && !isRecoveryCodePage();
const is401 = error => Boolean(error && typeof error === 'object' && 'response' in error && error.response?.status === 401);
export const createErrorHandler = (operation, options) => {
  const reportError = (name, message, error) => {
    logError(new Error(`GraphQL Error - ${name || 'Unknown'}: ${message || 'Unknown'}`), {
      errorInfo: JSON.stringify(error)
    }, {
      graphql: {
        operation,
        options: JSON.stringify(options ?? null)
      }
    });
  };
  return ({
    name,
    message,
    graphQLErrors,
    networkError
  }) => {
    if (is401(networkError) && shouldRedirectOn401()) {
      return logoutAndRedirect();
    }
    if (graphQLErrors) {
      graphQLErrors.forEach(error => reportError(name, message, error));
    }
  };
};
/**
 * Helper type to assert that a type is exactly equal to another type.
 *
 * @example
 * type Example = IsExact<StoryType, 'feature' | 'bug' | 'chore'>;
 * //   ^? type Example = true
 */

/**
 * A function to assert a type is exactly equal to another type at compile time (no-op at runtime).
 *
 * Initially introduced for Data Layer <> REST API adapters as we used to have enums to represent values,
 * and we now rely on union types. In most cases, values expressed by enums are the same as the union types,
 * but we want to ensure that we don't drift away from the original values so we can safely cast them.
 *
 * @param expectTrue - A boolean value that should be true if the type is exactly equal to another type.
 *
 * @example
 * assertType<IsExact<StoryType, 'feature' | 'bug' | 'chore'>>(true);
 */

export function assertType(expectTrue) {}
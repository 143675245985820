/**
 * Use to represent an expected error (mostly from a GraphQL validation error),
 * that shouldn't be logged on Sentry, but still handled by the UI.
 */
export class ExpectedError extends Error {
  constructor(message) {
    super(message);
    this.name = 'ExpectedError';
    Object.setPrototypeOf(this, ExpectedError.prototype);
  }
}
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.map.js";
import { useApolloClient } from '@apollo/client';
import { useDataLayerUpdateEvent } from './useDataLayerUpdateEvent';
import { Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
const SKIP_INVALIDATION = ['FilterPillOptions'];
const invalidateUpdatedItems = (client, items) => {
  if (!items.length) return;
  const updates = items.map(item => client.cache.identify(item)).filter(Boolean).map(cacheId => {
    return client.refetchQueries({
      updateCache(cache) {
        cache.modify({
          id: cacheId,
          fields(_, {
            INVALIDATE
          }) {
            return INVALIDATE;
          }
        });
      }
    });
  });
  Promise.all(updates);
};
export const InvalidateCacheOnUpdate = ({
  children
}) => {
  const client = useApolloClient();
  useDataLayerUpdateEvent(items => {
    invalidateUpdatedItems(client, items);
  }, {
    filter: item => !SKIP_INVALIDATION.includes(item.__typename)
  });
  return _jsx(_Fragment, {
    children: children
  });
};